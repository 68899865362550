<template>
  <div class="w-full h-full">

    <da-loader v-bind:overlay="isLoading"></da-loader>
    <da-header-title :title="'Plan de seguimiento'" class="shadow-md font-medium tracking-wider" rtlcornerlg>
      <template #actions>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="openModalAdd()">
              <v-icon  color="#E7AC18">mdi-file-plus</v-icon>
            </v-btn>
          </template>
          <span>Agregar Registro</span>
        </v-tooltip>
      </template>
    </da-header-title>

    <div class="mt-5 px-5 py-5">
        <da-datatable :headers="headers" :values="getMonitorings" :elevation="3">

            <template #action="{ data }">
                <div class="flex sm:px md:px-10 lg:px-10">
                  
                  <template> 
                      <da-button-primary @click.native="showModalData(data)" :label="'Detalle'" r2xl swpr class="w-full px-4  tracking-wider rounded-r-none"></da-button-primary>
                      <da-button-900 @click.native="showDelete(data.id_tracing)" :label="'Eliminar'" r2xl swpr class="w-full px-4  tracking-wider rounded-l-none"></da-button-900>
                  </template>

                </div>
            </template>

        </da-datatable>
    </div>

    <v-dialog v-model="show" persistent max-width="800" >
      <v-card>
        <div class="flex mb-5">
          <span class="text-xl ml-5 mt-4">Agregar Seguimiento</span> 
            <div class="flex justify-end items-center mr-4 mt-2">
                <svg xmlns="http://www.w3.org/2000/svg" @click="show = false" class="icon icon-tabler icon-tabler-square-x text-gray-400 cursor-pointer" width="35" height="35" viewBox="0 0 24 24" stroke-width="1" stroke="#BDBDBD" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <rect x="4" y="4" width="16" height="16" rx="2" />
                <path d="M10 10l4 4m0 -4l-4 4" />
                </svg>
            </div>
        </div>
        <v-card-text>

          <da-date-picker-field
          :label="'Fecha Actual:'"
          :placeholder="'Ejem: 24-01-1995'"
          :value="objCita3.quote_date_now"
          @update="objCita3.quote_date_now = $event"
          :rules="rules.date"
          ></da-date-picker-field>

          <div class="w-ful text-right">
            <span v-if="showtto2 == true" class="text-primary-700 cursor-pointer" @click="showtto2 = !showtto2">Escribir TTO</span>
            <span v-else class="text-primary-700 cursor-pointer" @click="showtto2 = !showtto2">Seleccionar TTO</span>
          </div>

          <da-text-field-primary 
              v-if="showtto2 == false"
              class="animate-fade-in-up"
              :label="'Tratamiento:'"
              :value="objCita3.id_cat_treatment_now"
              v-model="objCita3.id_cat_treatment_now"
              text
          />

          <v-autocomplete v-if="showtto2 == true" class="animate-fade-in-up" :items="getAllTreatments" item-text="name" v-model="ttoSelecionado1" item-value="id" label="Tratamiento:" color="#E7AC18"></v-autocomplete>

          <da-date-picker-field
          :label="'Fecha próximo TTO:'"
          :placeholder="'Ejem: 24-01-1995'"
          :value="objCita3.quote_date_after"
          @update="objCita3.quote_date_after = $event"
          :rules="rules.date"
          ></da-date-picker-field>

          <div class="w-ful text-right">
            <span v-if="showtto3 == true" class="text-primary-700 cursor-pointer" @click="showtto3 = !showtto3">Escribir TTO</span>
            <span v-else class="text-primary-700 cursor-pointer" @click="showtto3 = !showtto3">Seleccionar TTO</span>
          </div>

          <da-text-field-primary 
            v-if="showtto3 == false"
            class="animate-fade-in-up"
            :label ="'TTO Próxima cita:'"
            :value ="objCita3.id_cat_treatment_after"
            v-model ="objCita3.id_cat_treatment_after"
            text
          />

         <v-autocomplete v-if="showtto3 == true" class="animate-fade-in-up" :items="getAllTreatments" item-text="name" v-model="ttoSelecionado" item-value="id" label="TTO Próxima cita:" color="#E7AC18"></v-autocomplete>

          <da-text-area-primary 
            :label="'Notas'"           
            :value="objCita3.note"
            v-model="objCita3.note"> 
          </da-text-area-primary>

          <div class="flex justify-end">
              <v-btn class="mt-5" color="#F8C73D" :rounded="true" size="x-large"   @click="onSaveMonitoring()">
                <v-icon class="mr-1" color="white">mdi mdi-content-save-outline</v-icon>
                <p class="text-white mt-4">Guardar</p>
              </v-btn>
              
              <v-btn class="mt-5 ml-2" color="#A6001E" :rounded="true" size="x-large"   @click="show = false">
                <v-icon class="mr-1" color="white">mdi-delete-circle</v-icon>
                <p class="text-white mt-4">Cancelar</p>
              </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <da-dialog v-model="isDelete" :persist="true" :maxWidth="'500px'">
        <template #body>
            <da-sweet-confirm 
            :title="'Eliminar Registro'" 
            :message="'¿Esta seguro de eliminar el registro de seguimiento seleccionado?'"
            :lottiepath="DELETE"
            :iconwidth="150"
            :iconheight="150"
            v-on:confirm="onDelete()" 
            v-on:close="isDelete = false">
        </da-sweet-confirm>
        </template>
    </da-dialog>

    <v-dialog v-model="isNote" persistent max-width="800" >
      <v-card>
        <div class="flex mb-5">
          <span class="text-xl ml-5 mt-4">Detalle del seguimiento</span> 
            <div class="flex justify-end items-center mr-4 mt-2">
                <svg xmlns="http://www.w3.org/2000/svg" @click="closeModalEdit()" class="icon icon-tabler icon-tabler-square-x text-gray-400 cursor-pointer" width="35" height="35" viewBox="0 0 24 24" stroke-width="1" stroke="#BDBDBD" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <rect x="4" y="4" width="16" height="16" rx="2" />
                <path d="M10 10l4 4m0 -4l-4 4" />
                </svg>
            </div>
        </div>
        <v-card-text v-if="isReadOnly">

          <da-text-field-primary 
            :label="'Fecha TTO. Anterior:'"
            :value="objCita2.quote_date_now"
            v-model="objCita2.quote_date_now"
            v-mask="'##-##-####'"
            text
            :placeholder="'Ejem: 24-01-1995'"
            :readonly="true"
          >
          </da-text-field-primary>

          <da-text-field-primary 
              :label="'Tratamiento:'"
              :value="objCita2.id_cat_treatment_now"
              v-model="objCita2.id_cat_treatment_now"
              :readonly="true"
              text
          />

          <da-text-field-primary 
              :label="'Fecha próximo TTO:'"
              :value="objCita2.quote_date_after"
              v-model="objCita2.quote_date_after"
              v-mask="'##-##-####'"
              text
              :placeholder="'Ejem: 24-01-1995'"
              :readonly="true"
            >
          </da-text-field-primary>

          <da-text-field-primary 
            :label ="'TTO Próxima cita:'"
            :value ="objCita2.id_cat_treatment_after"
            v-model ="objCita2.id_cat_treatment_after"
            :readonly="true"
            text
          />

          <da-text-area-primary 
            :label="'Notas'"           
            :value="objCita2.note"
            v-model="objCita2.note"
            :readonly="true"
          > 
          </da-text-area-primary>

      <div class="flex justify-end">
            <v-btn v-if="!isEdit" class="mt-5 ml-2" color="#F8C73D" :rounded="true" size="x-large"   @click="changeButton()">
              <p class="text-white mt-4">Editar</p>
            </v-btn>
            <v-btn v-if="isEdit" class="mt-5 ml-2" color="#F8C73D" :rounded="true" size="x-large"   @click.native="onUPDMonitoring()">
              <p class="text-white mt-4">Guardar</p>
            </v-btn>
            <v-btn class="mt-5 ml-2" color="#A6001E" :rounded="true" size="x-large"   @click="isNote = false">
              <p class="text-white mt-4">Cerrar</p>
            </v-btn>
      </div>
        </v-card-text>

        <v-card-text v-else>

          <da-date-picker-field
          :label="'Fecha Actual:'"
          :placeholder="'Ejem: 24-01-1995'"
          :value=" objCita.quote_date_now"
          @update=" objCita.quote_date_now = $event"
          :rules="rules.date"
          ></da-date-picker-field>

          <div class="w-ful text-right">
            <span v-if="showtto1 == true" class="text-primary-700 cursor-pointer" @click="showtto1 = !showtto1">Seleccionar TTO</span>
            <span v-else class="text-primary-700 cursor-pointer" @click="showtto1 = !showtto1">Escribir TTO</span>
          </div>

          <da-text-field-primary 
              v-show="showtto1 == true"
              class="animate-fade-in-up"
              :label="'Tratamiento:'"
              :value="objCita.id_cat_treatment_now"
              v-model="objCita.id_cat_treatment_now"
              text
          />

          <v-autocomplete v-show="showtto1 == false" class="animate-fade-in-up" :items="getAllTreatments" item-text="name" v-model="ttoSelecionado2" item-value="name" label="Tratamiento:" color="#E7AC18"></v-autocomplete>
          <da-date-picker-field
          :label="'Fecha próximo TTO:'"
          :placeholder="'Ejem: 24-01-1995'"
          :value="objCita.quote_date_after"
          @update="objCita.quote_date_after = $event"
          :rules="rules.date"
          ></da-date-picker-field>

          <div class="w-ful text-right">
            <span v-if="showtto == true" class="text-primary-700 cursor-pointer" @click="showtto = !showtto">Seleccionar TTO2</span>
            <span v-else class="text-primary-700 cursor-pointer" @click="showtto = !showtto">Escribir TTO</span>
          </div>

          <da-text-field-primary 
            v-show="showtto == true"
            class="animate-fade-in-up"
            :label ="'TTO Próxima cita:'"
            :value ="objCita.id_cat_treatment_after"
            v-model ="objCita.id_cat_treatment_after"
            text
          />

         <v-autocomplete v-show="showtto == false" class="animate-fade-in-up" :items="getAllTreatments" item-text="name" v-model="ttoSelecionado3" item-value="name" label="TTO Próxima cita:" color="#E7AC18"></v-autocomplete>

          <da-text-area-primary 
            :label="'Notas'"           
            :value="objCita.note"
            v-model="objCita.note"> 
          </da-text-area-primary>

      <div class="flex justify-end">

            <v-btn class="mt-5" color="#F8C73D" :rounded="true" size="x-large"   @click="onUPDMonitoring()">
              <v-icon class="mr-1" color="white">mdi mdi-content-save-outline</v-icon>
              <p class="text-white mt-4">Guardar</p>
            </v-btn>
            
            <v-btn class="mt-5 ml-2" color="#A6001E" :rounded="true" size="x-large"   @click="cancelEditButton()">
              <v-icon class="mr-1" color="white">mdi-delete-circle</v-icon>
              <p class="text-white mt-4">Cancelar</p>
            </v-btn>
      </div>
        </v-card-text>

      </v-card>
    </v-dialog>

  </div> 

</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VuexMixin from '../../../mixins/VuexMixin';
import moduleTypes from '../../../store/modules/common/module-types';
import fnTypes from '../../../store/modules/common/fn-types';
import DASweetConfirm from '../../../core/components/form/dialog/components/DASweetConfirm.vue';
import { DELETE } from '../../../utils/lottieicon';
import { formatFecha, revertFormatFecha } from '../../../utils/date';
import DAMonitorPlanModel from '../../../models/monitor/DAMonitorPlan.model';

export default {
    mixins: [VuexMixin],
    props: {
    px: {
      type: Object,
      default: function(){return {}}
    }
  },
  data: function() {
        return {
            citas:  [],
            listTtrat:   [],
            showtto:    false,
            showtto1:   false,
            showtto2:   false,
            showtto3:   false,
            show:       false,
            isLoading:  false,
            isDelete:   false,
            isNote:     false,
            isEdit:     false,
            isReadOnly: true,
            ttoSelecionado:   '',
            ttoSelecionado1:  '',
            ttoSelecionado2:   '',
            ttoSelecionado3:  '',
            idtracing:  '',
            dateNow: '',
            ida: '',
            fecha: '',
            DELETE,
            objCita: {
              quote_date_now:         '',
              id_cat_treatment_now:   '',
              quote_date_after:       '',
              id_cat_treatment_after: '',
              note:                   ''
            },
            objCita2: {
              quote_date_now:         '',
              id_cat_treatment_now:   '',
              quote_date_after:       '',
              id_cat_treatment_after: '',
              note:                   ''
            },
            objCita3: new DAMonitorPlanModel(),
            headers: [
                { text: 'Fecha',                value: 'quote_date_now' },
                { text: 'Tipo de Tratamiento',  value: 'treatment_now' },
                { text: 'TTO Próx Cita',        value: 'treatment_after' },
                { text: 'Próxima Cita',         value: 'quote_date_after' },
                { text: 'Acción',               value: 'action' },
            ],
            valxx: '',
            rules: {
              date: [
                (v) => !!v || "El campo es requerido",
                (v) =>
                  (v && v.replace(/\D/g, "").length === 8) ||
                  "La fecha es incorrecta",
              ],
            },
        }
       
    },
    components: {
        'da-sweet-confirm': DASweetConfirm
    },
  created() {
      this.isLoading = true
      this.init()
      this.isLoading = false
  },
  computed: {
    ...mapGetters(moduleTypes.MONITORING, {
      saveMonitorings:    fnTypes.saveTracing,
      updMonitorings:     fnTypes.updTracing,
      getMonitorings:     fnTypes.getTracing,
      deleteMonitorings:  fnTypes.deleteTracing,
      getAllTreatments:   fnTypes.getAllPxTreatment
    }) ,
  },
  methods: {
    formatFecha,
    revertFormatFecha,
    ...mapActions(moduleTypes.MONITORING, [
        fnTypes.saveTracing,
        fnTypes.updTracing,
        fnTypes.getTracing,
        fnTypes.deleteTracing,
        fnTypes.getAllPxTreatment
      ]),

      init(){
        this.onGetMonitoring()
        this.onGetAllTreatment()
        this.getCurrentDate()
      },
    
      onDelete: async function(){
        this.isLoading = true
        let data = {
          "id_tracing": this.ida,
          "id_person":  this.px.person.id_person
        }
        await this.deleteTracing(data)
        this.onGetMonitoring()
        this.isDelete = false
        this.isLoading = false
      },

      onSaveMonitoring: async function(){

        let objeto = this.getAllTreatments.find((elemento) => elemento.id === this.ttoSelecionado1);//Tto Actual
        let objeto1 = this.getAllTreatments.find((elemento) => elemento.id === this.ttoSelecionado); //prox Tto

        var ttoActual = ''
        var ttoProximo = ''
        
        //Validacion tto Actual
        if (this.showtto2 == true){
          ttoActual = objeto.name
        }
        else {
          ttoActual = this.objCita3.id_cat_treatment_now
        }

        if (this.showtto3 == true){
          ttoProximo = objeto1.name
        }
        else{
          ttoProximo =  this.objCita3.id_cat_treatment_after 
        }

        if (ttoActual == '' || ttoProximo == '') {
          console.log("entro 9")
          this.isLoading = false
          return 
        }

        if(this.objCita3.quote_date_after == '' || this.objCita3.quote_date_after == undefined){
          this.objCita3.quote_date_after == '00-00-0000'
        }

        if(this.objCita3.quote_date_now == '' || this.objCita3.quote_date_now == undefined){
          this.objCita3.quote_date_now ==  this.dateNow
        }

        this.isLoading = true

        let data  = {
          "tracing":    [
          {
            "quote_date_now":    this.formatFecha(this.objCita3.quote_date_now),
            "treatment_now":    ttoActual,
            "quote_date_after": this.formatFecha(this.objCita3.quote_date_after),
            "treatment_after":  ttoProximo,
            "note":             this.objCita3.note,
          }
        ],
          "id_person":  this.px.person.id_person
        }

          await this.saveTracing(data)
        
        if(this.saveMonitorings){
          this.show = false
          this.onGetMonitoring()
        }
        this.isLoading = false
      },

      onUPDMonitoring: async function(){

        let objeto = this.getAllTreatments.find((elemento) => elemento.name === this.ttoSelecionado3);

        let objeto1 = this.getAllTreatments.find((elemento) => elemento.name === this.ttoSelecionado2);

        var tto = ''
        var tto1 = ''

        if (this.showtto){
          tto = this.objCita.id_cat_treatment_after 
        }
        else{
          if(objeto != undefined)
          {
            tto =  objeto.name
          }
          else {
              tto = this.objCita.id_cat_treatment_after 
          }
        }

        if (this.showtto1){
          tto1 = this.objCita.id_cat_treatment_now
        }
        else{
          if(objeto1 != undefined)
          {
            tto1 =  objeto1.name
          }
          else {
              tto1 = this.objCita.id_cat_treatment_now 
          }
        }

        this.isLoading = true

        if (tto == '' || tto1 == '') {
           this.isLoading = false
          return 
        }


        if(this.objCita.quote_date_after == '' || this.objCita.quote_date_after == undefined){
          this.objCita.quote_date_after == '00-00-0000'
        }

        if(this.objCita.quote_date_now == '' || this.objCita.quote_date_now == undefined){
          this.objCita.quote_date_now ==  this.dateNow
        }

        let data  = {
          "tracing":    [
              {
                "id_tracing":       this.idtracing,
                "quote_date_now":    this.formatFecha(this.objCita.quote_date_now),
                "treatment_now":    tto1,
                "quote_date_after":  this.formatFecha(this.objCita.quote_date_after),
                "treatment_after":  tto,
                "note":             this.objCita.note,
              }
        ],
          "id_person":  this.px.person.id_person
        }


          await this.updTracing(data)
        
        
        if(this.updMonitorings){
          this.isReadOnly = true
          this.isEdit = false
           this.isNote = false
          this.onGetMonitoring()
        }
        this.isLoading = false
      },

      onGetMonitoring: async function(){
        this.isLoading = true
        await this.ActionWrapp(moduleTypes.MONITORING, fnTypes.getTracing, this.px.person.id_person)
        this.citas = this.getMonitorings
        this.isLoading = false
      },

      onGetAllTreatment: async function(){
        this.isLoading = true
        await this.ActionWrapp(moduleTypes.MONITORING, fnTypes.getAllPxTreatment, this.px.person.id_person)
        this.isLoading = false
      },

      getCurrentDate() {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        this.objCita3.quote_date_now = `${year}-${month}-${day}`
        this.dateNow = `${year}-${month}-${day}`
      },

      openModalAdd(){
        this.getCurrentDate()
        this.show = true
        this.objCita3.id_cat_treatment_now   = ''
        this.objCita3.id_cat_treatment_after = ''
        this.objCita3.quote_date_after       = ''
        this.objCita3.note                   = ''
        this.ttoSelecionado                 = ''
        this.ttoSelecionado1                = ''
      },

      showDelete(id){
        this.ida = id
        this.isDelete = true
      },

      showModalData(obj){
        this.objCita2 = {};

        this.objCita2.quote_date_now         = obj.quote_date_now
        this.objCita2.id_cat_treatment_now   = obj.treatment_now
        this.objCita2.id_cat_treatment_after = obj.treatment_after
        this.objCita2.quote_date_after       = obj.quote_date_after
        this.objCita2.note                   = obj.note
        this.isNote           = true
        this.idtracing        = obj.id_tracing
        this.ttoSelecionado   =  obj.treatment_after
        this.ttoSelecionado1  =  obj.treatment_now
        this.ttoSelecionado2  =  obj.treatment_now
        this.ttoSelecionado3  =  obj.treatment_after

        let proxTtoExisteEnLista = this.getAllTreatments.find((elemento) => elemento.name === obj.treatment_after);
        let actualTtoExisteEnLista = this.getAllTreatments.find((elemento) => elemento.name === obj.treatment_now);

        if(proxTtoExisteEnLista != undefined)
          {
            this.showtto = false
          }
          else {
            this.showtto = true
          }

          if(actualTtoExisteEnLista != undefined)
          {
            this.showtto1 = false
          }
          else {
            this.showtto1 = true
          }

        this.objCita  = {...this.objCita2, quote_date_after: this.revertFormatFecha(obj.quote_date_after), quote_date_now: this.revertFormatFecha(obj.quote_date_now)};
      },

      changeButton(){
        this.isReadOnly = false
        this.isEdit = true
      },
      cancelEditButton(){
        this.isReadOnly = true
        this.isEdit     = false
        this.showtto    = false
        this.showtto1   = false
      },

      closeModalEdit(){
        this.isEdit = false
        this.showtto = false
        this.showtto1 = false
        this.isReadOnly = true
        this.esperarCincoSegundos()
      },

      esperarCincoSegundos() {
      setTimeout(() => {

        this.isNote = false
      }, 1000);

      
    },
    }
}
</script>

<style>

</style>